<template>
  <div class="list-employee-group-page">
    <KTCodePreview v-bind:title="'Danh sách nhóm nhân sự'">
      <template v-slot:toolbar>
        <b-row>
          <span
            typet="button"
            @click="redirectToCreateEmployeeGroup"
            v-show="checkPermission('EMPLOYEE_GROUP_INSERT')"
          >
            <b-button variant="primary" size="sm" class="font-weight-bolder">
              <i class="fa-solid fa-plus" style="font-size: 1rem"></i>
              Tạo mới
            </b-button>
          </span>
        </b-row>
      </template>
      <template v-slot:preview>
        <!-- Header session -->
        <!-- tag-->
        <b-row class="mb-5">
          <b-col cols="4">
            <label style="font-weight: 500px">Tìm theo tên nhóm nhân sự:</label>
            <b-input
              size="sm"
              placeholder="Nhập tên nhóm nhân sự"
              v-model.trim="params.name"
              single-line
              hide-details
              v-on:keyup.enter="onFilter()"
            ></b-input>
          </b-col>
        </b-row>
        <!--end tag-->
        <b-row class="mb-5">
          <b-col md="1">
            <b-button
              variant="primary"
              size="sm"
              class="btn font-weight-bold btn-size"
              @click="onFilter"
              :disabled="onLoading"
              >Lọc</b-button
            >
          </b-col>
        </b-row>
        <!-- End of Header session -->
        <!-- Table session -->
        <b-table
          :items="listEmployeeGroup"
          :fields="fields"
          bordered
          hover
          :busy="onLoading"
        >
          <template v-slot:table-busy>
            <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
          </template>
          <template v-slot:cell(createdAt)="row">
            {{ formatDate(row.item.createdAt, DATE_FORMAT) }}
          </template>
          <template v-slot:cell(actions)="row">
            <div class="d-flex justify-content-center">
              <b-dropdown size="sm" id="dropdown-left" no-caret right>
                <template slot="button-content">
                  <i class="flaticon2-settings"></i>
                </template>
                <b-dropdown-item
                  v-if="checkPermission('EMPLOYEE_GROUP_VIEW')"
                  @click="editItem(row.item)"
                >
                  <span style="color: #3f4254; font-size: 10px">
                    <i class="flaticon2-pen icon-size"></i>
                    &nbsp; Chi tiết
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="checkPermission('EMPLOYEE_GROUP_DELETE')"
                  @click="
                    showDeleteAlert(
                      `${modalDeleteTitle} '${row.item.name}'`,
                      modalDeleteDesc,
                      row.item,
                      confirmDeleteEmployeeGroup,
                    )
                  "
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i
                      style="font-size: 1rem; color: #d33"
                      class="flaticon2-rubbish-bin-delete-button"
                    ></i>
                    &nbsp; Xóa
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
        <!-- End of Table session -->

        <!-- Paginate session -->
        <b-row>
          <b-col>
            <p class="mt-3 text-dark font-weight-bold">
              Tổng:
              {{ totalItems }}
            </p>
          </b-col>
          <b-col>
            <BPaginationNavCustom
              :number-of-pages="totalPages || 1"
              :current-page="params.page"
              :total-page="totalPages"
              :linkParams="{}"
              @page-change="fetchData()"
            >
            </BPaginationNavCustom>
          </b-col>
        </b-row>
        <!-- End of Paginate session -->
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { VclTable } from 'vue-content-loading';
import 'vue-cute-timeline/dist/index.css';
import { makeToastSuccess } from '@/utils/common';
import { formatDate, DATE_FORMAT, CLIENT_DATE_TIME } from '@/utils/date';
import { showDeleteAlert } from '@/utils/sweet-alert2';
import BPaginationNavCustom from '@/view/base/bootstrap/BPaginationNavCustom.vue';
import { checkPermission } from '@/utils/saveDataToLocal';
import { cmdUrl } from '@/utils/apiUrl';
import { handleError } from '../../../utils/common';

export default {
  data() {
    return {
      linkParams: {},
      modalDeleteTitle: 'Xoá nhóm nhân sự',
      modalDeleteDesc:
        'Bạn có chắc muốn xoá nhóm nhân sự. Thao tác này sẽ không thể hoàn tác!',
      CLIENT_DATE_TIME: CLIENT_DATE_TIME,
      DATE_FORMAT: DATE_FORMAT,
      onLoading: false,
      listEmployeeGroup: [],
      fields: [
        {
          key: 'id',
          label: 'id',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            width: '8%',
            textAlign: 'center',
          },
          tdClass: 'text-center',
        },
        {
          key: 'code',
          label: 'Mã nhóm',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
        },
        {
          key: 'name',
          label: 'Tên nhóm',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'createdByName',
          label: 'Người tạo',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'createdAt',
          label: 'Ngày tạo',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'actions',
          label: '',
          sortable: false,
          thStyle: { width: '10%' },
        },
      ],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      totalPages: 1,
      totalItems: 0,
      params: {
        page: 1,
        limit: 10,
        name: '',
      },
    };
  },
  methods: {
    showDeleteAlert,
    formatDate,
    checkPermission,
    async confirmDeleteEmployeeGroup(item) {
      const endPoint = cmdUrl.employeeGroup.byId.replace('{id}', item.id);
      ApiService.delete(endPoint)
        .then(({ data }) => {
          makeToastSuccess(data?.message);
          this.listEmployeeGroup = this.listEmployeeGroup.filter(
            ({ id }) => id !== item.id,
          );
        })
        .catch((error) => {
          handleError(error);
        });
    },
    redirectToCreateEmployeeGroup() {
      this.$router.push({
        name: 'info-employee-group',
        query: {
          action: 'create',
        },
      });
    },
    editItem(item) {
      this.$router.push({
        name: 'info-employee-group',
        query: {
          id: item.id,
          action: 'update',
        },
      });
    },
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-employee-group',
      });
      this.fetchData();
    },
    getParamFilter() {
      const page = this.$route.query.page || 1;
      return {
        ...this.params,
        page,
      };
    },
    fetchData: async function () {
      try {
        if (this.onLoading) return;
        this.onLoading = true;
        const params = this.getParamFilter();
        const {
          data: { data },
        } = await ApiService.query(cmdUrl.employeeGroup.root, {
          params,
        });
        this.listEmployeeGroup = data.meta;
        this.totalItems = data.itemCount;
        this.totalPages = data.pageCount;
      } catch (error) {
        handleError(error);
      } finally {
        this.onLoading = false;
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Cấu hình nhóm nhân sự', route: 'employee-group' },
      { title: 'Danh sác nhóm nhân sự' },
    ]);
  },
  created() {
    this.fetchData();
  },
  components: {
    KTCodePreview,
    VclTable,
    BPaginationNavCustom,
  },
};
</script>

<style scoped>
.btn-size {
  width: 90px;
}

.icon-size {
  font-size: 1rem;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}

input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.icon:hover {
  background-color: #90c6fc;
}
</style>
<style lang="scss" scoped>
.list-employee-group-page {
  .dropdown-item-text {
    font-size: 12px;
    color: #3f4254;
  }
}
</style>
